import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpandablePanel = makeShortcode("ExpandablePanel");
const Swagger = makeShortcode("Swagger");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "seating-services-legacy"
    }}>{`Seating Services (legacy)`}</h1>
    <h2 {...{
      "id": "service-announcement"
    }}>{`Service announcement:`}</h2>
    <p>{`We are announcing end of life for the seating services API. This service will be deprecated as soon as all consumers of the service have migrated to the seating manager API. We plan for migrating all consumers during 2024 and we will contact each consumer to plan the migration together.`}</p>
    <p>{`We have migrated all train departures with seat reservations from Plads90 to new services, called Entur Plass. Next work in line is to replace the seating services API with seating manager API.`}</p>
    <p>{`If you experience anything unexpected with seat reservations, please let us know. To be continued!`}</p>
    <h2 {...{
      "id": "reserving-my-first-seat"
    }}>{`Reserving my first seat`}</h2>
    <p>{`Seating services deals with seat reservations and seat allocation. A seat is a physical seat in some sort of transport. A seat has a seat type, which gives the seat some special traits. It could either be an upgraded seat type, or it could be a zone in the transport for special purposes. The information about the seats and seat types should be defined in the seat allocation system, Plads 90.`}</p>
    <h2 {...{
      "id": "authentication"
    }}>{`Authentication`}</h2>
    <p>{`Seating services requires a Bearer Token in the Authorization header created by the Entur Authentication server for every request.`}</p>
    <h2 {...{
      "id": "checking-capacity"
    }}>{`Checking Capacity`}</h2>
    <p>{`The simplest way to check the capacity is to find all available seats on a departure between two stops. The identification of the departure is the original dated service journey that is created in the timetable module. The stops are given on the format from the Nasjonalt Stoppestedsregister.`}</p>
    <p>{`In this example we use the departure ENT:DatedServiceJourney:2838, the origin NSR:StopPlace:337 (Oslo) and the destination NSR:StopPlace:548 (Bergen).`}</p>
    <p><inlineCode parentName="p">{`GET /v1/dated-service-journeys/ENT:DatedServiceJourney:2838/seats?origin=NSR:StopPlace:337&destination=NSR:StopPlace:548`}</inlineCode></p>
    <ExpandablePanel title="Example response" style={{
      marginBottom: '1rem'
    }} mdxType="ExpandablePanel">
    <pre>
        {`[
    {
        "railcarId": 1,
        "railcarType": "A7-1",
        "compartments": [
            {
                "compartmentNumber": 1,
                "seatTypeId": 36,
                "targetGroup": "EG",
                "classType": 2,
                "smokingCode": 2,
                "seats": [
                    {
                        "seatNumber": 3,
                        "seatCode": 1
                    },
                    {
                        "seatNumber": 4,
                        "seatCode": 3
                    }
                ]
            },
            {
                "compartmentNumber": 2,
                "seatTypeId": 36,
                "targetGroup": "EG",
                "classType": 2,
                "smokingCode": 2,
                "seats": [
                    {
                        "seatNumber": 9,
                        "seatCode": 3
                    },
                    {
                        "seatNumber": 10,
                        "seatCode": 1
                    }
                ]
            }
        ]
    }
    {
        "railcarId": 4,
        "railcarType": "B7-6",
        "compartments": [
            {
                "compartmentNumber": 1,
                "seatTypeId": 15,
                "targetGroup": "EG",
                "classType": 2,
                "smokingCode": 2,
                "seats": [
                    {
                        "seatNumber": 3,
                        "seatCode": 1
                    },
                    {
                        "seatNumber": 4,
                        "seatCode": 3
                    }
                ]
            }
        ]
    },
    {
        "railcarId": 6,
        "railcarType": "B7-4",
        "compartments": [
            {
                "compartmentNumber": 2,
                "seatTypeId": 4,
                "targetGroup": "EG",
                "classType": 2,
                "smokingCode": 2,
                "seats": [
                    {
                        "seatNumber": 7,
                        "seatCode": 1
                    },
                    {
                        "seatNumber": 8,
                        "seatCode": 3
                    }
                ]
            },
            {
                "compartmentNumber": 3,
                "seatTypeId": 4,
                "targetGroup": "EG",
                "classType": 2,
                "smokingCode": 2,
                "seats": [
                    {
                        "seatNumber": 13,
                        "seatCode": 3
                    },
                    {
                        "seatNumber": 14,
                        "seatCode": 1
                    }
                ]
            }
        ]
    }
]
`}
    </pre>
    </ExpandablePanel>
    <p>{`We receive a 200 Ok, and a list of all the different seats available with their associated information. The seats are grouped into railcars, with an id and a type, which is information from Plads90. Each of the railcars are again grouped into compartments, and here the available seats are listed. In this response we have three different railcars, with different seat types in each of them.`}</p>
    <h2 {...{
      "id": "reserving-a-seat"
    }}>{`Reserving a Seat`}</h2>
    <p>{`There are different ways of reserving seats. The example request here reserve some specific targeted seats. For other reservation methods see REF. For all reservations one must define on what departure one wishes to reserve seats, between which stop places, and also what status the seat should have. In addition the request needs to have information that tells us which seats to reserve.`}</p>
    <p><inlineCode parentName="p">{`POST /v1/reservations/specific-seats`}</inlineCode></p>
    <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
    <pre>
        {`{
  "datedServiceJourneyId": "ENT:DatedServiceJourney:16343",
  "origin": "NSR:StopPlace:337",
  "destination": "NSR:StopPlace:548",
  "status": "CONFIRMED",
  "seatsToReserve" : [
    {
      "railcarNumber" : 1,
      "seatWithSeatTypes" : [
        {
        "seatTypeId": 36,
        "seatNumber" : 3
        }
      ]
    }
  ]
}
`}
    </pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response" style={{
      marginBottom: '1rem'
    }} mdxType="ExpandablePanel">
    <pre>
        {`{
    "reservationId": "bbec3ae8-d589-40c7-b0ae-ba27f528ea9d",
    "created": "2018-11-27T09:34:59.918489Z",
    "createdBy": "SEATING_SERVICES",
    "departureId": "63",
    "departureTime": "2018-12-04T14:43:00.887Z",
    "arrivalTime": "2018-12-04T21:21:00.887Z",
    "origin": "NSR:StopPlace:337",
    "destination": "NSR:StopPlace:548",
    "organisationId": 1,
    "datedServiceJourneyId": "ENT:DatedServiceJourney:16339",
    "seats": [
        {
            "seatId": "3396ec89-76b6-4a44-8608-4bd5500e6ae7",
            "reservationId": "bbec3ae8-d589-40c7-b0ae-ba27f528ea9d",
            "seatNumber": "3",
            "seatTypeId": 36,
            "seatCode": "1",
            "railcarId": "1",
            "created": "2018-11-27T09:34:59.918402Z",
            "createdBy": "SEATING_SERVICES",
            "status": "CONFIRMED",
            "controlId": "760087345910"
        }
    ]
}
`}
    </pre>
    </ExpandablePanel>
    <p>{`In this example we use the same departure, origin and destination as we used when we checked the capacity. The status is set to CONFIRMED to make a hard reservation. We choose the first seat in the capacity we got above, from railcar 1, with seatTypeId 36, and the seatNumber 3. The response returns a 201 Created, and information of the reservation that was made.`}</p>
    <h1 {...{
      "id": "guides"
    }}>{`Guides`}</h1>
    <h2 {...{
      "id": "seat-types"
    }}>{`Seat types`}</h2>
    <p>{`A seat type is a specific type of seat in Plads90, standard, comfort, dog free zone etc. SeatTypeId is the ID created in Plads90, and Seating services use this for different purposes, for example when making a reservation and to
present more meta data about the seats.`}</p>
    <h3 {...{
      "id": "api"
    }}>{`API`}</h3>
    <ExpandablePanel title="GET /v1/seat-types" mdxType="ExpandablePanel">
    Example response
    <pre>
        {`
    [
        {
            "id": 18,
            "seatTypeId": 4,
            "code": "ST",
            "description": "Standard seat",
            "validFrom": "2019-08-05T15:00:00+02:00",
            "seatClass": "",
            "organisationId": 99
        },
        {
            "id": 25,
            "seatTypeId": 4,
            "code": "ØK",
            "description": "Old standard seat type",
            "validFrom": "2015-08-05T15:00:00+02:00",
            "validTo": "2019-08-05T15:00:00+02:00",
            "seatClass": "",
            "organisationId": 99
        }
    ]
`}
    </pre>
    </ExpandablePanel>
    <ExpandablePanel title="POST /v1/seat-types" mdxType="ExpandablePanel">
    Example request
    <pre>
        {`
    {
        "id": 100,
        "code": "BP",
        "description": "Barnevognplass",
        "validFrom": "2019-08-01T00:00:00.784Z",
        "seatClass": ""
    }
`}
    </pre>
    Example response
    <pre>
        {`
    {
        "id": 100,
        "seatTypeId": 31,
        "code": "BP",
        "description": "Barnevognplass",
        "validFrom": "2019-08-01T00:00:00.784Z",
        "seatClass": "",
        "organisationId": 99
    }
`}
    </pre>
    </ExpandablePanel>
    <ExpandablePanel title="PUT /v1/seat-types/:id" mdxType="ExpandablePanel">
    Example request PUT /v1/seat-types/100
    <pre>
        {`
    {
        "seatTypeId": 31,
        "code": "BP",
        "description": "Barnevognplass",
        "validFrom": "2019-08-01T00:00:00.784Z",
        "validTo": "2019-08-06T00:00:00.784Z",
        "seatClass": ""
    }
`}
    </pre>
    Example response PUT /v1/seat-types/100
    <pre>
        {`
    {
        "id": 100,
        "seatTypeId": 31,
        "code": "BP",
        "description": "Barnevognplass",
        "validFrom": "2019-08-01T00:00:00.784Z",
        "validTo": "2019-08-06T00:00:00.784Z",
        "seatClass": ""
    }
`}
    </pre>
    </ExpandablePanel>
    <ExpandablePanel title="DELETE /v1/seat-types/:id" mdxType="ExpandablePanel">
    Response 204 if seat type is deleted.
    </ExpandablePanel>
    <h3 {...{
      "id": "prerequisite"
    }}>{`Prerequisite`}</h3>
    <ul>
      <li parentName="ul">{`Fully configured Plads90`}</li>
      <li parentName="ul">{`International code which is used by other foreign operator. (Hermes)`}</li>
    </ul>
    <h3 {...{
      "id": "seat-types-1"
    }}>{`Seat types`}</h3>
    <table>
    <thead>
        <tr>
            <th align="left">Id</th>
            <th align="left">Description</th>
            <th align="left">Plads90 code</th>
            <th align="left">Text on ticket</th>
            <th align="left">International</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td align="left">2, 4</td>
            <td align="left">Standard seat (2nd class)</td>
            <td align="left">ST</td>
            <td align="left">Standard plass</td>
            <td align="right">&nbsp;Yes</td>
        </tr>
        <tr>
            <td align="left">6</td>
            <td align="left">Wheelchair seat/space</td>
            <td align="left">RS</td>
            <td align="left">Rullestol</td>
            <td align="right">Yes</td>
        </tr>
        <tr>
            <td align="left">8</td>
            <td align="left">Handicap sleeper</td>
            <td align="left">HC</td>
            <td align="left">Rullestol sove</td>
            <td align="right">No</td>
        </tr>
        <tr>
            <td align="left">9</td>
            <td align="left">Sleeper</td>
            <td align="left">HK</td>
            <td align="left">Hel kupe</td>
            <td align="right">Yes</td>
        </tr>
        <tr>
            <td align="left">10</td>
            <td align="left">Family compartment</td>
            <td align="left">BF</td>
            <td align="left">Familie</td>
            <td align="right">Yes</td>
        </tr>
        <tr>
            <td align="left">15</td>
            <td align="left">Dyrefri/Allergiker</td>
            <td align="left">DF</td>
            <td align="left">Dyrefri/Allergiker</td>
            <td align="right">&nbsp;Yes</td>
        </tr>
        <tr>
            <td align="left">31</td>
            <td align="left">Baby carriage</td>
            <td align="left">BP</td>
            <td align="left">Barnevognplass</td>
            <td align="right">Yes</td>
        </tr>
        <tr>
            <td align="left">36</td>
            <td align="left">Comfort seat (1st class)</td>
            <td align="left">KO</td>
            <td align="left">NSB KOMFORT</td>
            <td align="right">Yes</td>
        </tr>
        <tr>
            <td align="left">41</td>
            <td align="left">Large animal (+40cm/dog)</td>
            <td align="left">DT</td>
            <td align="left">Tillat for dyr</td>
            <td align="right">Yes</td>
        </tr>
        <tr>
            <td align="left">107</td>
            <td align="left">Adjecent seat</td>
            <td align="left">LB</td>
            <td align="left">NSB Familie</td>
            <td align="right">No</td>
        </tr>
        <tr>
            <td align="left">136</td>
            <td align="left">Night</td>
            <td align="left">KN</td>
            <td align="left">Komfort Natt</td>
            <td align="right">No</td>
        </tr>
        <tr>
            <td align="left">141</td>
            <td align="left">Small animal (-40cm/dog)</td>
            <td align="left">OD</td>
            <td align="left">Ordinær/Dyr tillatt</td>
            <td align="right">No</td>
        </tr>
        <tr>
            <td align="left">202</td>
            <td align="left">New comfort seat (1st class)</td>
            <td align="left">SR</td>
            <td align="left">Setereservasjon</td>
            <td align="right">No</td>
        </tr>
        <tr>
            <td align="left">211</td>
            <td align="left">Bicycle</td>
            <td align="left">RY</td>
            <td align="left">Bagasje sykkel</td>
            <td align="right">No</td>
        </tr>
        <tr>
            <td align="left">215</td>
            <td align="left">Standard/Nærplass</td>
            <td align="left">NÆ</td>
            <td align="left">Standard/Nærplass</td>
            <td align="right">No</td>
        </tr>
        <tr>
            <td align="left">998</td>
            <td align="left">
                Reserve compartment (only train conductor allow to reserve)
            </td>
            <td align="left">TK</td>
            <td align="left">Tjenestekupe</td>
            <td align="right">No</td>
        </tr>
        <tr>
            <td align="left">999</td>
            <td align="left">
                Reserve compartment (only train conductor allow to reserve)
            </td>
            <td align="left">RK</td>
            <td align="left">Reservekupe</td>
            <td align="right">No</td>
        </tr>
    </tbody>
    <caption>List of current available seat types</caption>
    </table>
    <h2 {...{
      "id": "seat-allocation-rules"
    }}>{`Seat allocation rules`}</h2>
    <p>{`Seating Services supports automatic seat allocation based on products, both preassigned fare- and supplement-products. This is achieved by creating different attributes for the products. These attributes define what seat
a product is given and how many.`}</p>
    <h3 {...{
      "id": "api-1"
    }}>{`API`}</h3>
    <ExpandablePanel title="GET /v1/product-attributes" mdxType="ExpandablePanel">
    Example response
    <pre>
        {`
[
    {
        "id": "ABC:PreassignedFareProduct:TOGAS",
        "addSeat": 1,
        "replaceMainSeat": false,
        "productType": "MAIN",
        "seatTypePriorities": [
            {
                "seatTypeId": 4,
                "priority": 1
            }
        ]
    },
    {
        "id": "ACB:SupplementProduct:SeatReservation",
        "addSeat": 0,
        "replaceMainSeat": true,
        "productType": "SUPPLEMENT",
        "seatTypePriorities": [
            {
                "seatTypeId": 202,
                "priority": 1
            }
        ]
    },
    {
        "id": "ABC:SupplementProduct:BicycleReservation",
        "addSeat": 1,
        "replaceMainSeat": false,
        "productType": "SUPPLEMENT",
        "seatTypePriorities": [
            {
                "seatTypeId": 211,
                "priority": 1
            }
        ]
    }
]
`}
    </pre>
    </ExpandablePanel>
    <ExpandablePanel title="POST /v1/product-attributes" mdxType="ExpandablePanel">
    Example request
    <pre>
        {`
    [
        {
            "id": "ENT:Random:Product:1",
            "addSeat": 0,
            "replaceMainSeat": true,
            "productType": "MAIN",
            "seatTypePriorities": [
                {
                    "seatTypeId": 211,
                    "priority": 1
                },
                {
                    "seatTypeId": 31,
                    "priority": 1
                }
            ]
        }
    ]
`}
    </pre>
    Example response
    <pre>
        {`
    [
        {
            "id": "ENT:Random:Product:1",
            "addSeat": 0,
            "replaceMainSeat": true,
            "productType": "MAIN",
            "seatTypePriorities": [
                {
                    "seatTypeId": 211,
                    "priority": 1
                },
                {
                    "seatTypeId": 31,
                    "priority": 1
                }
            ]
        }
    ]
`}
    </pre>
    </ExpandablePanel>
    <ExpandablePanel title="PUT /v1/product-attributes/:product-id" mdxType="ExpandablePanel">
    Example request PUT /v1/product-attributes/ENT:Random:Product:1
    <pre>
        {`
    {
        "addSeat": 0,
        "replaceMainSeat": true,
        "productType": "MAIN",
        "seatTypePriorities": [
            {
                "seatTypeId": 211,
                "priority": 1
            },
            {
                "seatTypeId": 31,
                "priority": 1
            }
        ]
    }
`}
    </pre>
    Example response PUT /v1/product-attributes/ENT:Random:Product:1
    <pre>
        {`
    {
        "id": "ENT:Random:Product:1",
        "addSeat": 0,
        "replaceMainSeat": true,
        "productType": "MAIN",
        "seatTypePriorities": [
            {
                "seatTypeId": 211,
                "priority": 1
            },
            {
                "seatTypeId": 31,
                "priority": 1
            }
        ]
    }
`}
    </pre>
    </ExpandablePanel>
    <ExpandablePanel title="DELETE /v1/product-attributes/:product-id" mdxType="ExpandablePanel">
    Response 204 if product-attribute is deleted.
    </ExpandablePanel>
    <h3 {...{
      "id": "prerequisites"
    }}>{`Prerequisites`}</h3>
    <p>{`The product attributes defined work together with seat types in Seating services and Plads90. As discussed earlier, the seat types defined in Seating services needs to be the same as in Plads90, or else the rules will not work as intended.`}</p>
    <h3 {...{
      "id": "concepts"
    }}>{`Concepts`}</h3>
    <p>{`Product Attributes define how seats should be allocated when a traveller buys a specific product or a combination of products. It is a connection between productIds from the product module to seat types,
with attributes determining how the products interact with each other.
An attribute consists of four values; "addSeat", "replaceMainSeat", "productType", "companionGuard" and "seatTypePriorities".`}</p>
    <p>{`"addSeat" describes how many seats the traveller should be allocated by the product. Typically a product would add one seat, but it's possible to set a value of more or even zero.`}</p>
    <p>{`The "productType" is an enum describing what type of product the product is. Seating services supports two types of products; MAIN or SUPPLEMENT. When reserving seats for a collection of products there can only
be one Main product in the collection.`}</p>
    <p>{`"replaceMainSeat" describes whether the product should override the allocation of the main product. This attribute only applies if the product is a SUPPLEMENT type.
For example a supplement product upgrading the travellers seat would have a value of zero set to "addSeat" and "replaceMainSeat" set to True. If the product should, in addition to replacing the main product, add more seats
the "addSeat" value must be set. In a collection of products to reserve there can only be one supplement product at the time with this attribute set to True.`}</p>
    <p>{`The list of seatTypes a product can be allocated is defined by "seatTypePriorities". This is a list of seatTypes and the priority in which the system should try to allocate seats
to the traveller for the product. A product can have several seatTypes in this list as long as they have different priorities.`}</p>
    <p>{`The last attribute "companionGuard" defines whether or not the seats allocated for this product should be changeable by to the companion rules. When set to true the seats will not change, no matter what other
seats where allocated in the reservation request.`}</p>
    <h4 {...{
      "id": "companion-rules"
    }}>{`Companion rules`}</h4>
    <p>{`In addition to product attributes, the system also supports rules that allocate seats based off of what other seats were allocated in the request.
A typical use case for this is to keep travellers who bought more than one ticket gathered together, even though the product attributes dictates that they should be given two different seatTypes in different cabins.`}</p>
    <h3 {...{
      "id": "api-2"
    }}>{`API`}</h3>
    <ExpandablePanel title="GET /v1/companion-for-seats" mdxType="ExpandablePanel">
    Example response
    <pre>
        {`
    [
        {
            "id": 1,
            "seatType": {
                "id": 7,
                "seatTypeId": 41,
                "code": "DT",
                "description": "Tillatt for dyr (+40cm/hund)",
                "validFrom": "2019-02-15T11:40:04.510543Z",
                "seatClass": "",
                "companionSeatTypes": [
                    {
                        "seatType": {
                            "id": 10,
                            "seatTypeId": 141,
                            "code": "OD",
                            "description": "Ordinær/Dyr tillatt (-40cm)",
                            "validFrom": "2019-02-15T11:40:04.510543Z",
                            "seatClass": "",
                            "companionSeatTypes": [],
                            "organisationId": 1
                        },
                        "priority": 1
                    },
                    {
                        "seatType": {
                            "id": 7,
                            "seatTypeId": 41,
                            "code": "DT",
                            "description": "Tillatt for dyr (+40cm/hund)",
                            "validFrom": "2019-02-15T11:40:04.510543Z",
                            "seatClass": "",
                            "companionSeatTypes": [],
                            "organisationId": 20
                        },
                        "priority": 2
                    }
                ],
                "organisationId": 1
            },
            "companionSeatType": {
                "id": 10,
                "seatTypeId": 141,
                "code": "OD",
                "description": "Ordinær/Dyr tillatt (-40cm)",
                "validFrom": "2019-02-15T11:40:04.510543Z",
                "seatClass": "",
                "companionSeatTypes": [
                    {
                        "seatType": {
                            "id": 10,
                            "seatTypeId": 141,
                            "code": "OD",
                            "description": "Ordinær/Dyr tillatt (-40cm)",
                            "validFrom": "2019-02-15T11:40:04.510543Z",
                            "seatClass": "",
                            "companionSeatTypes": [],
                            "organisationId": 1
                        },
                        "priority": 1
                    },
                    {
                        "seatType": {
                            "id": 7,
                            "seatTypeId": 41,
                            "code": "DT",
                            "description": "Tillatt for dyr (+40cm/hund)",
                            "validFrom": "2019-02-15T11:40:04.510543Z",
                            "seatClass": "",
                            "companionSeatTypes": [],
                            "organisationId": 1
                        },
                        "priority": 2
                    }
                ],
                "organisationId": 1
            },
            "priority": 1
        },
        {}....
    ]
`}
    </pre>
    </ExpandablePanel>
    <ExpandablePanel title="POST /v1/companion-for-seats" mdxType="ExpandablePanel">
    Example request
    <pre>
        {`
    {
        "seatTypeId": 41,
        "companionSeatTypeId": 141,
        "priority": 1
    }
`}
    </pre>
    Example response
    <pre>
        {`
    {
        "id": 1,
        "seatType": {
            "id": 2,
            "seatTypeId": 41,
            "code": "DT",
            "description": "Tillatt for dyr (+40cm/hund)",
            "validFrom": "2019-02-15T11:40:04.510543Z",
            "seatClass": "",
            "companionSeatTypes": [
                {
                    "seatType": {
                        "id": 3,
                        "seatTypeId": 141,
                        "code": "OD",
                        "description": "Ordinær/Dyr tillatt (-40cm)",
                        "validFrom": "2019-02-15T11:40:04.510543Z",
                        "seatClass": "",
                        "companionSeatTypes": [],
                        "organisationId": 1
                    },
                    "priority": 1
                }
            ]
        }
    }
`}
    </pre>
    </ExpandablePanel>
    <ExpandablePanel title="PUT /v1/companion-for-seats/:companionMappingId" mdxType="ExpandablePanel">
    Example request PUT /v1/companion-for-seats/1
    <pre>
        {`
    {
        "seatTypeId": 41,
        "companionSeatTypeId": 141,
        "priority": 1
    }
`}
    </pre>
    Example response PUT /v1/companion-for-seats/1
    <pre>
        {`
    {
        "id": 1,
        "seatType": {
            "id": 7,
            "seatTypeId": 41,
            "code": "DT",
            "description": "Tillatt for dyr (+40cm/hund)",
            "validFrom": "2019-02-15T11:40:04.510543Z",
            "seatClass": "",
            "organisationId": 20
        },
        "companionSeatType": {
            "id": 10,
            "seatTypeId": 141,
            "code": "OD",
            "description": "Ordinær/Dyr tillatt (-40cm)",
            "validFrom": "2019-02-15T11:40:04.510543Z",
            "seatClass": "",
            "organisationId": 20
        },
        "priority": 1
    }
`}
    </pre>
    </ExpandablePanel>
    <ExpandablePanel title="DELETE /v1/companion-for-seats/:companionMappingId" mdxType="ExpandablePanel">
    Response 204 if seat type is deleted.
    </ExpandablePanel>
    <h3 {...{
      "id": "prerequisites-1"
    }}>{`Prerequisites`}</h3>
    <p>{`These rules are only respected when reserving seats with products, so the product attributes must exists for the companion rules to work.`}</p>
    <h3 {...{
      "id": "concepts-1"
    }}>{`Concepts`}</h3>
    <p>{`Similarly to the product attributes, the companion rules define a list of prioritized seats. The system use this list to determine if some of the allocated seats dictate that the other seats must be changed.
For example the seat for wheelchair can be configured to dictate that the other seats in the reservations should be changed to a seat type close to the wheelchair seat.
This rule is based around seat type instead of products.`}</p>
    <h2 {...{
      "id": "available-seatscapacity"
    }}>{`Available seats/capacity`}</h2>
    <p>{`The capacity/availability API gives information about capacity for departures. This includes seat status,
seat type (kupè type), litra code for the railcar and railcar info. The API supports a set of different ways to
track a seat/railcar availability and offers a specific search for available seats by seat type.`}</p>
    <h3 {...{
      "id": "prerequisites-2"
    }}>{`Prerequisites`}</h3>
    <p>{`Fully configured Plads90 available (lines, trains, seat types, departures etc.). This is not self service, it has to be done in cooperation with Entur.`}</p>
    <p>{`Core concepts:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`A seat is an item which can be reserved for a specific departure in the Plads90 system. A seat will contain information on seat type, seat number and seat placement.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A railcar is a reference to a train consisting of a single coach (carriage, car), with a driver's cab at one or both ends.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A compartment is a space within a railcar. In Plads90 each seat type will have a compartment space from 2, 4, 6 and 8 seats depends on railcar setting.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A dated-service-journey is a reference for allocate departure info. Dated-service-journey will contain the private id, service journey id and departure date time.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A requestScrolling - F is an inquiry on the specific train/coach, B is paging from the mentioned coach to the next one.`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "departure-capacity"
    }}>{`Departure capacity`}</h3>
    <ExpandablePanel title="POST /v1/dated-service-journeys/:datedServiceJourneyId/total-seat-capacities?origin=:origin&destination=:destination" style={{
      marginBottom: '1rem',
      marginTop: '1rem'
    }} mdxType="ExpandablePanel">
    Example response GET
    /v1/dated-service-journeys/:datedServiceJourneyId/total-seat-capacities?origin=NSR:StopPlace:337&destination=NSR:StopPlace:548
    <pre>
        {`
    {
        "datedServiceJourneyId": "ENT:DatedServiceJourney:20473937",
        "numberOfSeats": [
            {
                "seatTypeId": 4,
                "totalSeats": 180
            },
            {
                "seatTypeId": 6,
                "totalSeats": 2
            },
            {
                "seatTypeId": 10,
                "totalSeats": 20
            },
            {
                "seatTypeId": 15,
                "totalSeats": 58
            },
            {
                "seatTypeId": 31,
                "totalSeats": 3
            },
            {
                "seatTypeId": 36,
                "totalSeats": 48
            },
            {
                "seatTypeId": 41,
                "totalSeats": 8
            },
            {
                "seatTypeId": 107,
                "totalSeats": 4
            },
            {
                "seatTypeId": 141,
                "totalSeats": 10
            },
            {
                "seatTypeId": 998,
                "totalSeats": 16
            }
        ]
    }
`}
    </pre>
    </ExpandablePanel>
    <p>{`Total seat capacity will give default capacity (disregarding availability) for all seat types on a specific date-service-journey given origin station and destination station.`}</p>
    <ExpandablePanel title="POST /v1/dated-service-journeys/seat-capacities" style={{
      marginBottom: '1rem',
      marginTop: '1rem'
    }} mdxType="ExpandablePanel">
    Example request
    <pre>
        {`
    [
        {
            "datedServiceJourneyId": "ENT:DatedServiceJourney:20473937",
            "destination": "NSR:StopPlace:337",
            "origin": "NSR:StopPlace:548"
        }
    ]
`}
    </pre>
    Example response
    <pre>
        {`
    [
        {
            "datedServiceJourneyId": "ENT:DatedServiceJourney:20473937",
            "capacities": [
                {
                    "seatTypeId": 36,
                    "seatClass": "",
                    "numberAvailableSeats": 48,
                    "applicableProducts": [
                        "NSB:SupplementProduct:MiniPriceSeatUpgrade",
                        "NSB:SupplementProduct:SeatUpgrade"
                    ]
                },
                {
                    "seatTypeId": 31,
                    "seatClass": "pushchair",
                    "numberAvailableSeats": 3,
                    "applicableProducts": [
                        "NSB:SupplementProduct:PushChairReservation"
                    ]
                },
                {
                    "seatTypeId": 107,
                    "seatClass": "",
                    "numberAvailableSeats": 4,
                    "applicableProducts": [
                        "NSB:PreassignedFareProduct:Ordinary",
                        "NSB:PreassignedFareProduct:MiniPrice",
                        "NSB:PreassignedFareProduct:Trondelag",
                        "NSB:PreassignedFareProduct:Bergensbanen"
                    ]
                }
            ]
        }
    ]
`}
    </pre>
    </ExpandablePanel>
    <p>{`Seat capacity will give available capacity for each seat types with applicable products for a specific dated-service-journey.`}</p>
    <h3 {...{
      "id": "available-railcars-and-litra-info"
    }}>{`Available railcars and litra info`}</h3>
    <p>{`Available railcars of a departure`}</p>
    <ExpandablePanel title="GET /v1/dated-service-journeys/:datedServiceJourneyId/railcars?origin=:origin&destination=:destination" style={{
      marginBottom: '1rem',
      marginTop: '1rem'
    }} mdxType="ExpandablePanel">
    Example response GET
    /v1/dated-service-journeys/ENT:DatedServiceJourney:20473937/railcars?origin=-NSR:StopPlace:337&destination=NSR:StopPlace:548
    <pre>
        {`
    [
        {
            "railcarNumber": "1",
            "railcarDescription": "A7-1 KO",
            "litraCode": "A7-1"
        },
        {
            "railcarNumber": "2",
            "railcarDescription": "BC7-1 BK BF RS LB",
            "litraCode": "BC7-1"
        },
        {
            "railcarNumber": "4",
            "railcarDescription": "B7-6 DF",
            "litraCode": "B7-6"
        },
        {
            "railcarNumber": "6",
            "railcarDescription": "B7-4 ØK",
            "litraCode": "B7-4"
        },
        {
            "railcarNumber": "7",
            "railcarDescription": "B7-4 ØK",
            "litraCode": "B7-4"
        },
        {
            "railcarNumber": "8",
            "railcarDescription": "B7-5 ØK DT OD",
            "litraCode": "B7-5"
        }
    ]
`}
    </pre>
    </ExpandablePanel>
    <p>{`Available railcars will give a list of all available railcars to a specific date-service-journey, origin station and destination station.`}</p>
    <ExpandablePanel title="GET /v1/dated-service-journeys/:datedServiceJourneyId/railcars/config" style={{
      marginBottom: '1rem',
      marginTop: '1rem'
    }} mdxType="ExpandablePanel">
    Example response GET
    /v1/dated-service-journeys/:datedServiceJourneyId/railcars/config
    <pre>
        {`
    [
        {
            "railcarNumber": "1",
            "railcarDescription": "",
            "litraCode": "A7-1"
        },
        {
            "railcarNumber": "2",
            "railcarDescription": "",
            "litraCode": "BC7-1"
        },
        {
            "railcarNumber": "4",
            "railcarDescription": "",
            "litraCode": "B7-6A"
        },
        {
            "railcarNumber": "6",
            "railcarDescription": "",
            "litraCode": "B7-4A"
        },
        {
            "railcarNumber": "7",
            "railcarDescription": "",
            "litraCode": "B7-4A"
        },
        {
            "railcarNumber": "8",
            "railcarDescription": "",
            "litraCode": "B7-5A"
        }
    ]
`}
    </pre>
    </ExpandablePanel>
    <p>{`A list of railcars with litra code to a specific date-service-journey. This service is needed because Seatmap service
does not contains any information about vehicle material for a specific date-service-journey.`}</p>
    <h3 {...{
      "id": "available-seats"
    }}>{`Available seats`}</h3>
    <p>{`Available seats of a departure can be searched by 3 different way.`}</p>
    <ExpandablePanel title="GET /v1/dated-service-journeys/:datedServiceJourneyId/railcars/:railcarNumber/seats?origin=:origin&destination=:destination" style={{
      marginBottom: '1rem'
    }} mdxType="ExpandablePanel">
    Example response GET
    /v1/dated-service-journeys/ENT:DatedServiceJourney:20227388/railcars/1/seats?origin=NSR:StopPlace:337&destination=NSR:StopPlace:548
    <pre>
        {`
    {
      "railcarNumber": "1",
      "litraCode": "A7-1",
      "compartments": [
          {
              "compartmentNumber": 1,
              "seatTypeId": 36,
              "targetGroup": "EG",
              "classType": 2,
              "smokingCode": 2,
              "seats": [
                  {
                      "seatNumber": 3,
                      "seatCode": 1,
                      "available": true
                  },
                  {
                      "seatNumber": 4,
                      "seatCode": 3,
                      "available": true
                  }
              ],
              "applicableProducts": [
                  "ENT:PreassignedFareProduct:MiniPriceSeatUpgrade",
                  "ENT:SupplementProduct:SeatUpgrade"
              ]
          },
          {
              "compartmentNumber": 2,
              "seatTypeId": 36,
              "targetGroup": "EG",
              "classType": 2,
              "smokingCode": 2,
              "seats": [
                  {
                      "seatNumber": 9,
                      "seatCode": 3,
                      "available": true
                  }
              ],
              "applicableProducts": [
                  "ENT:PreassignedFareProduct:MiniPriceSeatUpgrade",
                  "ENT:SupplementProduct:SeatUpgrade"
              ]
          }
      ]
  }
`}
    </pre>
    </ExpandablePanel>
    <p>{`The first way is given by a dated-service-journey, railcarId, origin station and destination station.
It will return a list of available seats for the specific railcar number.`}</p>
    <ExpandablePanel title="GET /v1/dated-service-journeys/:datedServiceJourneyId/seats?origin=:origin&destination=:destination" style={{
      marginBottom: '1rem',
      marginTop: '1rem'
    }} mdxType="ExpandablePanel">
    Example response GET
    /v1/dated-service-journeys/ENT:DatedServiceJourney:20227388/seats?origin=NSR:StopPlace:337&destination=NSR:StopPlace:548
    <pre>
        {`
    [
      {
          "railcarNumber": "1",
          "litraCode": "A7-1",
          "compartments": [
              {
                  "compartmentNumber": 1,
                  "seatTypeId": 36,
                  "targetGroup": "EG",
                  "classType": 2,
                  "smokingCode": 2,
                  "seats": [
                      {
                          "seatNumber": 3,
                          "seatCode": 1,
                          "available": true
                      },
                      {
                          "seatNumber": 4,
                          "seatCode": 3,
                          "available": true
                      }
                  ],
                  "applicableProducts": [
                      "ENT:SupplementProduct:MiniPriceSeatUpgrade",
                      "ENT:SupplementProduct:SeatUpgrade"
                  ]
              },
              {
                  "compartmentNumber": 2,
                  "seatTypeId": 36,
                  "targetGroup": "EG",
                  "classType": 2,
                  "smokingCode": 2,
                  "seats": [
                      {
                          "seatNumber": 9,
                          "seatCode": 3,
                          "available": true
                      }
                  ],
                  "applicableProducts": [
                      "ENT:SupplementProduct:MiniPriceSeatUpgrade",
                      "ENT:SupplementProduct:SeatUpgrade"
                  ]
              },
              {
                  "compartmentNumber": 3,
                  "seatTypeId": 36,
                  "targetGroup": "EG",
                  "classType": 2,
                  "smokingCode": 2,
                  "seats": [
                      {
                          "seatNumber": 11,
                          "seatCode": 1,
                          "available": true
                      },
                      {
                          "seatNumber": 12,
                          "seatCode": 3,
                          "available": true
                      },
                      {
                          "seatNumber": 15,
                          "seatCode": 1,
                          "available": true
                      },
                      {
                          "seatNumber": 16,
                          "seatCode": 3,
                          "available": true
                      }
                  ],
                  "applicableProducts": [
                      "ENT:SupplementProduct:MiniPriceSeatUpgrade",
                      "ENT:SupplementProduct:SeatUpgrade"
                  ]
              }
          ]
      },
      {
          "railcarNumber": "6",
          "litraCode": "B7-4A",
          "compartments": [
              {
                  "compartmentNumber": 1,
                  "seatTypeId": 4,
                  "targetGroup": "EG",
                  "classType": 2,
                  "smokingCode": 2,
                  "seats": [
                      {
                          "seatNumber": 3,
                          "seatCode": 1,
                          "available": true
                      },
                      {
                          "seatNumber": 4,
                          "seatCode": 3,
                          "available": true
                      },
                      {
                          "seatNumber": 7,
                          "seatCode": 1,
                          "available": true
                      },
                      {
                          "seatNumber": 8,
                          "seatCode": 3,
                          "available": true
                      }
                  ],
                  "applicableProducts": [
                      "ENT:PreassignedFareProduct:Ordinary",
                      "ENT:PreassignedFareProduct:MiniPrice",
                      "ENT:PreassignedFareProduct:Trondelag",
                      "ENT:PreassignedFareProduct:Bergensbanen",
                      "ENT:PreassignedFareProduct:Raumabanen",
                      "ENT:PreassignedFareProduct:RaumabanenPeak"
                  ]
              }
          ]
      }
  ]
`}
    </pre>
    </ExpandablePanel>
    <p>{`The second way is given by a dated-service-journey, origin station and destination station. It will return all available seats for the specific dated-service-journey (train).`}</p>
    <ExpandablePanel title="GET /v1/dated-service-journeys/:datedServiceJourneyId/railcars/seats?travellers=:travellers&origin=:origin&destination=:destination&seatTypeId=:seatTypeId" style={{
      marginBottom: '1rem',
      marginTop: '1rem'
    }} mdxType="ExpandablePanel">
    Example response GET
    /v1/dated-service-journeys/ENT:DatedServiceJourney:20473937/railcars/seats?travellers=1&origin=NSR:StopPlace:337&destination=NSR:StopPlace:548&seatTypeId=4
    <pre>
        {`
    {
        "railcarNumber": "6",
        "litraCode": "B7-4A",
        "compartments": [
            {
                "compartmentNumber": 11,
                "seatTypeId": 4,
                "seats": [
                    {
                        "seatNumber": 65,
                        "seatCode": 0,
                        "available": true
                    },
                    {
                        "seatNumber": 66,
                        "seatCode": 0,
                        "available": true
                    },
                    {
                        "seatNumber": 67,
                        "seatCode": 0,
                        "available": true
                    },
                    {
                        "seatNumber": 68,
                        "seatCode": 0,
                        "available": true
                    }
                ],
                "applicableProducts": [
                    "ENT:PreassignedFareProduct:Ordinary",
                    "ENT:PreassignedFareProduct:MiniPrice"
                ]
            },
            {
                "compartmentNumber": 10,
                "seatTypeId": 4,
                "seats": [
                    {
                        "seatNumber": 57,
                        "seatCode": 0,
                        "available": true
                    },
                    {
                        "seatNumber": 58,
                        "seatCode": 0,
                        "available": true
                    },
                    {
                        "seatNumber": 59,
                        "seatCode": 0,
                        "available": true
                    }
                ],
                "applicableProducts": [
                    "ENT:PreassignedFareProduct:Ordinary",
                    "ENT:PreassignedFareProduct:MiniPrice"
                ]
            }
        ]
    }
`}
    </pre>
    </ExpandablePanel>
    <p>{`The third way is using seat type. Plads90 will try to find the best combination by number of travelers and seat type
and return 2-3 different suggested available seats in a railcar. It is a limitation in the second search method because
different departures has different compartment setting (ref. core concepts).`}</p>
    <p>{`Even it is available seats in Plads90 for that departure, but the number of travelers have exceeded the maximum compartment space, the "Get list of best available seats" might return error or no available seats.`}</p>
    <h2 {...{
      "id": "reservations"
    }}>{`Reservations`}</h2>
    <p>{`The reservations part of the seating service offers services to create (draft and confirmed), read/get, update/change and delete/cancel a reservation, and a service to get all reservations on a given departure.`}</p>
    <h3 {...{
      "id": "prerequisites-3"
    }}>{`Prerequisites`}</h3>
    <ul>
      <li parentName="ul">{`Fully configured Plads90 available (lines, trains, seat types, departures etc.). This is not self service, it has to be done in cooperation with Entur.`}</li>
      <li parentName="ul">{`Available seat(s) to reserve.`}</li>
    </ul>
    <h3 {...{
      "id": "make-a-reservation"
    }}>{`Make a Reservation`}</h3>
    <p>{`A reservation can consist of one or multiple seat reservations, of one or multiple seat types on one departure between a origin and a destination.
Seats can be reserved in three different ways with two different statuses. The first option is to get assigned seats automatically,
the second option is to reserve by product and the third option is to reserve specific seats. One and only one of the reservation types must
be used in a single reservation. All three ways can be reserved with status "DRAFT" or "CONFIRMED".`}</p>
    <h4 {...{
      "id": "draft-vs-confirmed"
    }}>{`Draft vs. confirmed`}</h4>
    <p>{`If a reservation is made with status set to "CONFIRMED" the seats are reserved immediately and an confirmation of the booking with an reservation id
is given as the response. If a reservation is made with status set to "DRAFT" an confirmation of a "soft booking" with an reservation id
is given as the response. Unless an update to the specific soft reservation with status update to "CONFIRMED" for all seats in the reservation using
the reservation id from the response happens within a given time the reservation will be set to status "EXPIRED", and the seats in question will
be released for others to reserve. How long an reservation will live as "DRAFT" is controlled by a sales component called Session Manager.`}</p>
    <h3 {...{
      "id": "reserve---automatic-assignment-of-seats"
    }}>{`Reserve - automatic assignment of seats`}</h3>
    <ExpandablePanel title="POST /v1/reservations/by-seat-types" style={{
      marginBottom: '1rem',
      marginTop: '1rem'
    }} mdxType="ExpandablePanel">
    Example request
    <pre>
        {`
    {
        "datedServiceJourneyId": "ENT:DatedServiceJourney:19871132",
        "origin": "NSR:StopPlace:519",
        "destination": "NSR:StopPlace:702",
        "status": "CONFIRMED",
        "seatTypesToReserve": [
            {
                "travellers": 1,
                "seatTypeId": 36
            }
        ]
    }
`}
    </pre>
    Example response
    <pre>
        {`
    {
        "id": "421479f0-b5db-4939-9e37-b08914d1390g",
        "created": "2019-08-07T08:17:31.672932Z",
        "createdBy": "1",
        "vehicleNumber": "729",
        "departureTime": "2019-10-01T13:47:00Z",
        "arrivalTime": "2019-10-01T19:50:00Z",
        "origin": "NSR:StopPlace:519",
        "destination": "NSR:StopPlace:702",
        "organisationId": 77,
        "datedServiceJourneyId": "ENT:DatedServiceJourney:19871132",
        "seats": [
            {
                "id": "97179821-9303-4868-9a23-391be967cd13",
                "reservationId": "421479f0-b5db-4939-9e37-b08914d1390g",
                "seatNumber": "25",
                "seatTypeId": 36,
                "seatCode": "3",
                "railcarNumber": "1",
                "created": "2019-08-07T08:17:31.672851Z",
                "createdBy": "1",
                "status": "CONFIRMED",
                "controlId": "760060173183"
            }
        ]
    }
`}
    </pre>
    </ExpandablePanel>
    <p>{`This is the simplest way to reserve seats on a given train (datedServiceJourney), between a given origin and a given destination.
Reservations can be done on multiple seat types for a given number of travelers. The response will among other things consist of
a reservation id and information about assigned seat(s) (railcar, seat number, control id etc.). The reservation id and control id(s)
can be used later if one wishes to read information about, change or cancel a reservation or specific seats, i.e change a reservation from draft to confirmed.`}</p>
    <h3 {...{
      "id": "reserve---seats-assigned-by-product"
    }}>{`Reserve - seats assigned by product`}</h3>
    <ExpandablePanel title="POST /v1/reservations/by-products" style={{
      marginTop: '1rem'
    }} mdxType="ExpandablePanel">
    Example request
    <pre>
        {`
    {
        "datedServiceJourneyId": "ENT:DatedServiceJourney:10578277",
        "origin": "NSR:StopPlace:337",
        "destination": "NSR:StopPlace:337",
        "status": "DRAFT",
        "productsToReserve": [
            {
                "travellers": 1,
                "productId": "ENT:PreassignedFareProduct:Ordinary",
                "supplementProducts": [
                    "ENT:SupplementProduct:ChildAndParent"
                ]
            }
        ]
    }
`}
    </pre>
    Example response
    <pre>
        {`
    {
       "reservation": {
          "id": "53d92c41-1f56-484f-a3a3-3dfddc22976b",
          "created": "2019-08-06T14:05:31.388105Z",
          "createdBy": "1",
          "vehicleNumber": "729",
          "departureTime": "2019-10-01T13:47:00Z",
          "arrivalTime": "2019-10-01T19:50:00Z",
          "origin": "NSR:StopPlace:519",
          "destination": "NSR:StopPlace:702",
          "organisationId": 77,
          "datedServiceJourneyId": "ENT:DatedServiceJourney:10578277",
          "seats": [
              {
                  "id": "90c43039-4ab5-4092-b494-d3ba2c2afca7",
                  "reservationId": "53d92c41-1f56-484f-a3a3-3dfddc22976b",
                  "seatNumber": "174",
                  "seatTypeId": 41,
                  "seatCode": "3",
                  "railcarNumber": "3",
                  "created": "2019-08-06T14:05:31.383852Z",
                  "createdBy": "1",
                  "status": "DRAFT",
                  "controlId": "760021053189"
              },
              {
                  "id": "11f2bc7f-58a9-4f0b-87a2-da92630f98af",
                  "reservationId": "53d92c41-1f56-484f-a3a3-3dfddc22976b",
                  "seatNumber": "171",
                  "seatTypeId": 41,
                  "seatCode": "3",
                  "railcarNumber": "3",
                  "created": "2019-08-06T14:05:31.388033Z",
                  "createdBy": "1",
                  "status": "DRAFT",
                  "controlId": "760029053189"
              }
          ]
        }
    }
`}
    </pre>
    </ExpandablePanel>
    <ExpandablePanel title="POST /v1/reservations/by-products-return-with-products" style={{
      marginBottom: '1rem'
    }} mdxType="ExpandablePanel">
Example request
      <pre>
        {`
{
    "datedServiceJourneyId": "ENT:DatedServiceJourney:19861013",
    "origin": "NSR:StopPlace:337",
    "destination": "NSR:StopPlace:548",
    "status": "CONFIRMED",
    "productsToReserve": [
        {
            "travellers": 1,
            "productId": "ENT:PreassignedFareProduct:Standard",
            "supplementProducts": ["ENT:SupplementProduct:ChildAndParent"]
        },
        {
            "travellers": 2,
            "productId": "ENT:PreassignedFareProduct:Standard",
            "supplementProducts": []
        }
    ]
}
`}
      </pre>
Example response
      <pre>
    {`
{
    "reservation": { ...

        "seats": [ ...
        ]
    },
    "productsWithSeatIds": [
        {
            "productId": "ENT:PreassignedFareProduct:Standard",
            "supplementProducts": [
                "ENT:SupplementProduct:ChildAndParent"
            ],
            "seatIds": [
                "f2b8f8d1-ca97-4964-9168-db08590c1fab",
                "b03a22f3-8865-4a9e-92b5-45665b7cb715"
            ]
        },
        {
            "productId": "ENT:PreassignedFareProduct:Standard",
            "supplementProducts": [
            ],
            "seatIds": [
                "c04af8d1-da41-5738-9283-vg08590c1fbn"
            ]
        },
        {
            "productId": "ENT:PreassignedFareProduct:Standard",
            "supplementProducts": [
            ],
            "seatIds": [
                "c67083a4-dda4-43f4-9119-3f1f2f88aa11"
            ]
        }
    ]
}
`}
      </pre>
    </ExpandablePanel>
    <p>{`Seats may also be reserved by product(s). Like with automatic assignment train, origin and destination has to be given
as well as information about wanted product(s), the amount of that/these product(s) and possible supplement
products.
Reserve by product has two possible endpoints depending on what response is wanted. The least
verbose (/v1/reservations/by-products) response will give reservation id and control id(s) that must be used if one
wishes to read information about, change or cancel a reservation or specific seats later. The other alternative
(/v1/reservations/by-products-return-with-products) will give the same response as mentioned and in addition, it will
give a list with information on which product a seat in the reservation corresponds to.`}</p>
    <p>{`While ordering seats based on product(s), the control of the reservation(s) is being transferred to an automatic
assignment of seats. This means that for an order, all the seats will be in the same railcar (if possible), but not necessarily
placed next to another if there are different seatTypes. In the event of an order for; one of a product that responds
with two seats required on sitting beside each other and two standard seats without any additional rules, the dependant
seats will always be seated next to each other, while the other passengers might not sit beside the dependant seats if
there are no more seats of the given seatType available. Albeit, all seats of the same seatType will be seated next to
each other, resulting in the standard seatTypes being seated together as well, although not necessarily at the same
seatType as initially specified, seatTypes can change in order to be sat together or to be placed in the same
railcar. If all seats of the same seatType can't be seated next to each other, the reservation
will not go through.`}</p>
    <h3 {...{
      "id": "reserve-specific-seats"
    }}>{`Reserve specific seat(s)`}</h3>
    <ExpandablePanel title="POST /v1/reservations/specific-seats" style={{
      marginBottom: '1rem',
      marginTop: '1rem'
    }} mdxType="ExpandablePanel">
    Example request
    <pre>
        {`
    {
        "datedServiceJourneyId": "ENT:DatedServiceJourney:19871132",
        "origin": "NSR:StopPlace:519",
        "destination": "NSR:StopPlace:702",
        "status": "DRAFT",
        "seatsToReserve": [
            {
                "railcarNumber": 3,
                "seatWithSeatTypes": [
                    {
                        "seatTypeId": 4,
                        "seatNumber": 97
                    }
                ]
            }
        ]
    }
`}
    </pre>
    Example response
    <pre>
        {`
    {
        "id": "834f9312-ddb9-4f3e-a69e-22e9e56c254g",
        "created": "2019-08-07T10:40:13.621261Z",
        "createdBy": "1",
        "vehicleNumber": "729",
        "departureTime": "2019-10-01T13:47:00Z",
        "arrivalTime": "2019-10-01T19:50:00Z",
        "origin": "NSR:StopPlace:519",
        "destination": "NSR:StopPlace:702",
        "organisationId": 77,
        "datedServiceJourneyId": "ENT:DatedServiceJourney:19871132",
        "seats": [
            {
                "id": "9569b147-b11f-4a91-bcc8-486bf87a0dd7",
                "reservationId": "834f9312-ddb9-4f3e-a69e-22e9e56c254g",
                "seatNumber": "97",
                "seatTypeId": 4,
                "seatCode": "3",
                "railcarNumber": "3",
                "created": "2019-08-07T10:40:13.62115Z",
                "createdBy": "1",
                "status": "DRAFT",
                "controlId": "760057401385"
            }
        ]
    }
`}
    </pre>
    </ExpandablePanel>
    <p>{`To reserve one or several specific seats, in addition to information about train, origin and destination, one have to
give information about railcar, seat type and seat number. The latter are information available through capacity services.
Like with automatic assignment of seats the response will give reservation id and control id(s) that must be used if one
wishes to read information about, change or cancel a reservation or specific seats later.`}</p>
    <h3 {...{
      "id": "get-information-about-a-specific-reservation"
    }}>{`Get information about a specific reservation`}</h3>
    <ExpandablePanel title="GET /v1/reservations/:reservationId" style={{
      marginTop: '1rem'
    }} mdxType="ExpandablePanel">
    Example response GET /v1/reservations/0740fa7b-8895-445c-a3a0-cd3d976cdf69
    <pre>
        {`
    {
        "id": "0740fa7b-8895-445c-a3a0-cd3d976cdf69",
        "created": "2019-03-26T11:35:52.700554Z",
        "createdBy": "1",
        "changed": "2019-03-26T11:55:58.425484Z",
        "changedBy": "1",
        "vehicleNumber": "745",
        "departureTime": "2019-03-29T21:25:00Z",
        "arrivalTime": "2019-03-30T06:20:00Z",
        "origin": "NSR:StopPlace:337",
        "destination": "NSR:StopPlace:596",
        "organisationId": 77,
        "datedServiceJourneyId": "ENT:DatedServiceJourney:10578277",
        "seats": [
            {
                "id": "3b66ef10-77ec-45fa-8752-69cb4307b1ab",
                "reservationId": "0740fa7b-8895-445c-a3a0-cd3d976cdf69",
                "seatNumber": "47",
                "seatTypeId": 4,
                "seatCode": "1",
                "railcarNumber": "3",
                "created": "2019-03-26T11:35:52.699938Z",
                "createdBy": "1",
                "changed": "2019-03-26T11:55:57.60121Z",
                "changedBy": "1",
                "status": "EXPIRED",
                "controlId": "760065355210"
            },
            {}....
    }
`}
    </pre>
    </ExpandablePanel>
    <ExpandablePanel title="GET /v1/reservations?datedServiceJourneyId=:datedServiceJourneyId" mdxType="ExpandablePanel">
    Example response GET
    /v1/reservations?datedServiceJourneyId=ENT:DatedServiceJourney:10578277
    <pre>
        {`
    [
        {
            "id": "0740fa7b-8895-445c-a3a0-cd3d976cdf69",
            "created": "2019-03-26T11:35:52.700554Z",
            "createdBy": "1",
            "changed": "2019-03-26T11:55:58.425484Z",
            "changedBy": "1",
            "vehicleNumber": "745",
            "departureTime": "2019-03-29T21:25:00Z",
            "arrivalTime": "2019-03-30T06:20:00Z",
            "origin": "NSR:StopPlace:337",
            "destination": "NSR:StopPlace:596",
            "organisationId": 77,
            "datedServiceJourneyId": "ENT:DatedServiceJourney:10578277",
            "seats": [
                {
                    "id": "3b66ef10-77ec-45fa-8752-69cb4307b1ab",
                    "reservationId": "0740fa7b-8895-445c-a3a0-cd3d976cdf69",
                    "seatNumber": "47",
                    "seatTypeId": 4,
                    "seatCode": "1",
                    "railcarNumber": "3",
                    "created": "2019-03-26T11:35:52.699938Z",
                    "createdBy": "1",
                    "changed": "2019-03-26T11:55:57.60121Z",
                    "changedBy": "1",
                    "status": "EXPIRED",
                    "controlId": "760065355210"
                },
                {}....
            ]
        }
    ]
`}
    </pre>
    </ExpandablePanel>
    <ExpandablePanel title="GET /v1/reservations?reservationId=:reservationId&reservationId=:reservationId...." style={{
      marginBottom: '1rem'
    }} mdxType="ExpandablePanel">
    Example response GET
    /v1/reservations?reservationId=752472be-8162-48bc-9f88-f5fac8d1c78b&reservationId=0740fa7b-8892-445c-a3a0-cd3d976cdf67
    <pre>
        {`
    [
        {
            "id": "752472be-8162-48bc-9f88-f5fac8d1c78b",
            "created": "2019-03-27T11:42:04.7082Z",
            "createdBy": "1",
            "changed": "2019-03-27T11:44:14.16359Z",
            "changedBy": "1",
            "vehicleNumber": "745",
            "departureTime": "2019-03-29T21:25:00Z",
            "arrivalTime": "2019-03-30T06:20:00Z",
            "origin": "NSR:StopPlace:337",
            "destination": "NSR:StopPlace:596",
            "organisationId": 77,
            "datedServiceJourneyId": "ENT:DatedServiceJourney:10833906",
            "seats": [
                {
                    "id": "4000cb70-c987-4617-bce6-d0dd262c1e01",
                    "reservationId": "752472be-8162-48bc-9f88-f5fac8d1c78b",
                    "seatNumber": "32",
                    "seatTypeId": 136,
                    "seatCode": "3",
                    "railcarNumber": "1",
                    "created": "2019-03-27T11:42:04.708117Z",
                    "createdBy": "1",
                    "changed": "2019-03-27T11:44:14.16035Z",
                    "changedBy": "1",
                    "status": "CONFIRMED",
                    "controlId": "760068420410"
                }
            ]
        },
        {
            "id": "0740fa7b-8895-445c-a3a0-cd3d976cdf69",
            "created": "2019-03-26T11:35:52.700554Z",
            "createdBy": "1",
            "changed": "2019-03-26T11:55:58.425484Z",
            "changedBy": "1",
            "vehicleNumber": "745",
            "departureTime": "2019-03-29T21:25:00Z",
            "arrivalTime": "2019-03-30T06:20:00Z",
            "origin": "NSR:StopPlace:337",
            "destination": "NSR:StopPlace:596",
            "organisationId": 77,
            "datedServiceJourneyId": "ENT:DatedServiceJourney:10578277",
            "seats": [
                {
                    "id": "3b66ef10-77ec-45fa-8752-69cb4307b1ab",
                    "reservationId": "0740fa7b-8895-445c-a3a0-cd3d976cdf69",
                    "seatNumber": "47",
                    "seatTypeId": 4,
                    "seatCode": "1",
                    "railcarNumber": "3",
                    "created": "2019-03-26T11:35:52.699938Z",
                    "createdBy": "1",
                    "changed": "2019-03-26T11:55:57.60121Z",
                    "changedBy": "1",
                    "status": "EXPIRED",
                    "controlId": "760065355210"
                }
            ]
        }
    ]
`}
    </pre>
    </ExpandablePanel>
    <p>{`Information about a specific reservation can be retrieved using the reservation id given from reserving seats in one of
the above mentioned methods. If a valid reservation id is given a reservation response is returned, this response hold
information about the reservation and the seats reserved. If the reservation id given is not valid, the requester is not
allowed to read the information or something else went wrong an error response is given.`}</p>
    <h3 {...{
      "id": "update-reservation"
    }}>{`Update reservation`}</h3>
    <ExpandablePanel title="PUT /v1/reservations/:reservationId" style={{
      marginBottom: '1rem',
      marginTop: '1rem'
    }} mdxType="ExpandablePanel">
    Example request
    <pre>
        {`
    {
         "created": "2019-08-07T10:40:13.621261Z",
         "createdBy": "1",
         "vehicleNumber": "729",
         "departureTime": "2019-10-01T13:47:00Z",
         "arrivalTime": "2019-10-01T19:50:00Z",
         "origin": "NSR:StopPlace:519",
         "destination": "NSR:StopPlace:702",
         "datedServiceJourneyId": "ENT:DatedServiceJourney:19871132",
         "seats": [
             {
                 "id": "9569b147-b11f-4a91-bcc8-486bf87a0dd7",
                 "reservationId": "834f9312-ddb9-4f3e-a69e-22e9e56c254g",
                 "seatNumber": "97",
                 "seatTypeId": 4,
                 "seatCode": "3",
                 "railcarNumber": "3",
                 "created": "2019-08-07T10:40:13.62115Z",
                 "createdBy": "1",
                 "status": "CONFIRMED",
                 "controlId": "760057401385"
             }
         ]
    }
`}
    </pre>
    Example response
    <pre>
        {`
    {
         "created": "2019-08-07T10:40:13.621261Z",
         "createdBy": "1",
         "changed": "2019-08-07T11:40:13.621261Z",
         "changedBy": "1",
         "vehicleNumber": "729",
         "departureTime": "2019-10-01T13:47:00Z",
         "arrivalTime": "2019-10-01T19:50:00Z",
         "origin": "NSR:StopPlace:519",
         "destination": "NSR:StopPlace:702",
         "organisationId": 77,
         "datedServiceJourneyId": "ENT:DatedServiceJourney:19871132",
         "seats": [
             {
                 "id": "9569b147-b11f-4a91-bcc8-486bf87a0dd7",
                 "reservationId": "834f9312-ddb9-4f3e-a69e-22e9e56c254g",
                 "seatNumber": "97",
                 "seatTypeId": 4,
                 "seatCode": "3",
                 "railcarNumber": "3",
                 "created": "2019-08-07T10:40:13.62115Z",
                 "createdBy": "1",
                 "changed": "2019-08-07T11:00:13.62115Z",
                 "changedBy": "1",
                 "status": "CONFIRMED",
                 "controlId": "760057401385"
             }
         ]
    }
`}
    </pre>
    </ExpandablePanel>
    <p>{`To update a reservation one needs to send the reservation model with updated information as the request body.
The update reservation service will return the updated Reservation and a http status on how the request went.`}</p>
    <h4 {...{
      "id": "attributes-which-can-be-changed-under-update-reservation"
    }}>{`Attributes which can be changed under update reservation:`}</h4>
    <ul>
      <li parentName="ul">{`seatNumber - Allowed to change to a available seat number`}</li>
      <li parentName="ul">{`seatTypeId - Allowed to change to another seatTypeId based on the specific seat number`}</li>
      <li parentName="ul">{`railcarNumber - Allowed to change to available railcar number based on seat number and seatTypeId`}</li>
      <li parentName="ul">{`status - Allowed status changes are, draft to confirmed, draft to expired, draft to cancelled and confirmed to cancelled.`}</li>
    </ul>
    <p>{`SeatCode will be automatic updated by the new choosen seat number. Changed a seat number which already exists in same
reservation is allowed and will not affect Plads90. Seat(s) which are no longer used in reservation will be
automatically cancelled by application.`}</p>
    <h5 {...{
      "id": "update-a-reservation-is-only-allowed-to-changing-seats-and-status-and-can-not-be-used-to-add-new-seats-please-use-reserve-api-which-is-mentioned-above-to-add-new-seats"
    }}>{`Update a reservation is only allowed to changing seats and status and can not be used to add new seats. Please use 'Reserve API' which is mentioned above to add new seats.`}</h5>
    <h3 {...{
      "id": "update-specific-seat-attributes-and-status"
    }}>{`Update specific seat attributes and status`}</h3>
    <ExpandablePanel title="PATCH /v1/reservations/seats/:seatId" style={{
      marginBottom: '1rem',
      marginTop: '1rem'
    }} mdxType="ExpandablePanel">
    Example request PATCH
    /v1/reservations/seats/345f4156-d113-4b8f-9807-4c67ff96a933
    <pre>
        {`
    {
      "status": "CANCELLED"
    }
`}
    </pre>
    Example response PATCH
    /v1/reservations/seats/345f4156-d113-4b8f-9807-4c67ff96a933
    <pre>
        {`
    {
        "id": "345f4156-d113-4b8f-9807-4c67ff96a933",
        "reservationId": "0740fa7b-8895-445c-a3a0-cd3d976cdf69",
        "seatNumber": "47",
        "seatTypeId": 4,
        "seatCode": "1",
        "railcarNumber": "3",
        "created": "2019-03-26T11:35:52.699938Z",
        "createdBy": "1",
        "changed": "2019-03-26T11:55:57.60121Z",
        "changedBy": "1",
        "status": "CANCELLED",
        "controlId": "760065355210"
    }
`}
    </pre>
    </ExpandablePanel>
    <p>{`To change a specific seat can use the update seat patch endpoint, where seat id is given in the path and body of the request gives what attributes want to change. Information about the changed seat is returned.`}</p>
    <h4 {...{
      "id": "attributes-which-can-be-changed-under-update-seat"
    }}>{`Attributes which can be changed under update seat:`}</h4>
    <ul>
      <li parentName="ul">{`seatNumber - Allowed to change to a available seat number`}</li>
      <li parentName="ul">{`seatTypeId - Allowed to change to another seatTypeId based on the specific seat number`}</li>
      <li parentName="ul">{`railcarNumber - Allowed to change to available railcar number based on seat number and seatTypeId`}</li>
      <li parentName="ul">{`status - Allowed status changes are, draft to confirmed, draft to expired, draft to cancelled and confirmed to cancelled.`}</li>
    </ul>
    <h3 {...{
      "id": "cancel-seats"
    }}>{`Cancel seat(s)`}</h3>
    <ExpandablePanel title="DELETE /v1/reservations/seats" style={{
      marginBottom: '1rem',
      marginTop: '1rem'
    }} mdxType="ExpandablePanel">
    Example request
    <pre>
        {`
    [
        {
            "controlNr" : "760042482682",
            "departureId" : "63999",
            "departureDate" : "2018-08-29",
            "sitting" : false
        }
    ]
`}
    </pre>
    Response 204 if given seats are cancelled.
    </ExpandablePanel>
    <p>{`The cancel seat(s) method in seating service are used to cancel seat(s) that are booked from outside seating services, through the Hermes reservation system. Canceling of seat(s) reserved through seating service can be cancelled by updating a reservation or specific seat(s), through the above mentioned service methods. The request consists of a list of CancelSeat, containing control number, departure id, date of departure and a flag(true/false) telling whether this is a sitting seat or not. Except from http status nothing is returned.`}</p>
    <h3 {...{
      "id": "get-information-about-all-reservations-on-a-specific-departure"
    }}>{`Get information about all reservations on a specific departure`}</h3>
    <p>{`All reservations on a departure can be found by calling the get all seat reservations method with the departures datedServiceJourney as input. The response will consist of a list of reservations.`}</p>
    <h3 {...{
      "id": "exception-handling"
    }}>{`Exception handling`}</h3>
    <p>{`If something goes not according to plan, bad request, requested resource not found or something else, in seating service a CommonError object is returned, with as much information as possible about what went wrong.`}</p>
    <h1 {...{
      "id": "api-reference"
    }}>{`API Reference`}</h1>
    <p><a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/seatingservices/v2/api-docs"
      }}>{`See the API reference on the Swagger Petstore`}</a></p>
    <Swagger url="https://api.entur.io/seatingservices/v2/api-docs" mdxType="Swagger" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      